import React from 'react'
import { Link } from 'react-router-dom'
import Mission11 from "../Image/mission.jpg"
import Vission11 from "../Image/vision.jpg"
function Mission() {
	return (
		<>

			<section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
				<div className="text-block">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 v-center">
								<div className="bread-inner">
									<div className="bread-menu">
										<ul>
											<li><Link to="/">Home</Link></li>
											<li><a href="#">Mission</a></li>
										</ul>
									</div>
									<div className="bread-title">
										<h2>Mission</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="service pad-tb">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="image-block upset bg-shape">
								<img src={Mission11} alt="image" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-8 block-1">
							<div className="common-heading text-l pl25">

								<h2>Our Mission</h2>
								<p>At N D Techland Pvt. Ltd., our mission is to empower businesses with innovative technology solutions that drive growth, foster excellence, and transform the digital landscape. Guided by our unwavering commitment to quality, integrity, and customer satisfaction, we strive to be the premier provider of IT services and solutions, delivering unparalleled value to our clients and stakeholders. </p>
								<h5 className="mt20 mb10">Mission Statement</h5>
								<ul className="list-style-">
									<li><b>Innovation:</b> We are dedicated to pushing the boundaries of innovation, leveraging the latest technologies, tools, and methodologies to develop cutting-edge solutions that address the evolving needs and challenges of our clients.</li>
									<li><b>Excellence:</b>	 We are committed to excellence in everything we do. From the initial consultation to the final delivery, we uphold the highest standards of quality, craftsmanship, and professionalism, ensuring that our solutions meet and exceed the expectations of our clients.</li>
									<li><b>Customer Satisfaction: </b>	We prioritize customer satisfaction above all else. We understand that our success is intrinsically tied to the success of our clients, and we go above and beyond to ensure that every interaction with N D Techland is positive, productive, and enriching.</li>


								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="service-block bg-gradient6 pad-tb light-dark">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<div className="common-heading ptag">
								<span>Values</span>
								<h2>N D Techland Core Values</h2>
								<p className="mb30">We think big and have hands in all leading technology platforms to provide you
									wide array of services.</p>
							</div>
						</div>
					</div>
					<div className="row upset link-hover">
						<div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
							<div className="s-block">
								<div className="s-card-icon"><img src="images/icons/logo-and-branding.svg" alt="service"
									className="img-fluid" /></div>
								<h4>Innovation</h4>
								<p>Innovation is what that always keeps us one step ahead of everyone.</p>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
							<div className="s-block">
								<div className="s-card-icon"><img src="images/icons/service2.svg" alt="service" className="img-fluid" />
								</div>
								<h4>Efficiency</h4>
								<p>Efficient work with effectiveness is always our key of success.</p>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
							<div className="s-block">
								<div className="s-card-icon"><img src="images/icons/service3.svg" alt="service" className="img-fluid" />
								</div>
								<h4>Teamwork</h4>
								<p>Our team of professional play hard to meet client's wish list.</p>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
							<div className="s-block">
								<div className="s-card-icon"><img src="images/icons/service4.svg" alt="service" className="img-fluid" />
								</div>
								<h4>Customer  Satisfaction</h4>
								<p>We not only say this, we mean it, customer's happiness is our first priority.</p>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1s">
							<div className="s-block">
								<div className="s-card-icon"><img src="images/icons/service5.svg" alt="service" className="img-fluid" />
								</div>
								<h4>Creativity</h4>
								<p>Our team's creativity makes every one of our project unique and easy to use.</p>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1.2s">
							<div className="s-block">
								<div className="s-card-icon"><img src="images/icons/service6.svg" alt="service" className="img-fluid" />
								</div>
								<h4> Client Satisfaction</h4>
								<p>Our client's satisfaction is what we keep in mind at each and every step of a project. From starting to end.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="service pad-tb">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 block-1">
							<div className="common-heading text-l pr25">

								<h2>Our Vision</h2>
								<p>Our vision at N D Techland is to be recognized as a global leader in IT services and solutions, known for our innovation, expertise, and unwavering commitment to customer satisfaction.
								</p>
								<h5 className="mt20 mb10">Vision Statement</h5>
								<ul className="list-style-">
									<li style={{ textAlign: "justify" }}> <b>Drive Digital Transformation:</b> We envision a future where businesses of all sizes and industries harness the power of technology to unlock new opportunities, streamline operations, and achieve sustainable growth and success.</li>
									<li style={{ textAlign: "justify" }}> <b>Empower Businesses:</b> We aim to empower businesses with the tools, insights, and resources they need to thrive in the digital age. By providing comprehensive IT services and solutions, we enable our clients to overcome challenges, seize opportunities, and stay ahead of the competition.</li>
									<li style={{ textAlign: "justify" }}><b>Build Lasting Relationships: </b> We seek to build lasting relationships with our clients, founded on trust, transparency, and mutual respect. We believe that by working collaboratively and strategically with our clients, we can achieve shared goals and create enduring value for all stakeholders.</li>


								</ul>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="image-block upset bg-shape m-mt30 mb0">
								<img src={Vission11} alt="image" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="cta-area pad-tb">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="common-heading">
								<span>Let's work together</span>
								<h2>We Love to Listen to Your Requirements</h2>
								<Link to="/contactus" className="btn-outline">Estimate Project <i
									className="fas fa-chevron-right fa-icon"></i></Link>
								<p className="cta-call">Or call us now <a href="tel:+0120 4354103"><i className="fas fa-phone-alt"></i>
								0120 4354103</a></p>
							</div>
						</div>
					</div>
				</div>
				<div className="shape shape-a1"><img src="images/shape/shape-3.svg" alt="shape" /></div>
				<div className="shape shape-a2"><img src="images/shape/shape-4.svg" alt="shape" /></div>
				<div className="shape shape-a3"><img src="images/shape/shape-13.svg" alt="shape" /></div>
				<div className="shape shape-a4"><img src="images/shape/shape-11.svg" alt="shape" /></div>
			</section>
		</>
	)
}

export default Mission