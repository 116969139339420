import React from 'react'
import { Link } from 'react-router-dom'
import './Ourteam.css'
function Ourteam() {
    return (
        <>
            <section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                <div className="text-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 v-center">
                                <div className="bread-inner">
                                    <div className="bread-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><a href="#">Our Team</a></li>
                                        </ul>
                                    </div>
                                    <div className="bread-title">
                                        <h2>Our Team</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team bg-gradient99 pad-tb">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="common-heading ptag">
                                <span>We Are Awesome</span>
                                <h2>Our Managing Directors</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCpY5LtQ47cqncKMYWucFP41NtJvXU06-tnQ&s" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#!">Vineet Mishra</a></h4>
                                    <p> CEO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKUkZR03RzHwhZYYF9cRKsDJ47QBuX8vAYCA&s" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#">Priynka Shukla</a></h4>
                                    <p> CTO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://st4.depositphotos.com/3557671/23892/v/450/depositphotos_238923408-stock-illustration-vector-illustration-of-avatar-and.jpg" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#">Punit Mishra</a></h4>
                                    <p>Company COO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team pad-tb deep-dark">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="common-heading ptag">
                                <h2>Our Core Team Members</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp91j3ei4_cBnZsMmMWZHFSID08C02iXbPcg&s" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Rakesh Kumar Gupta</h4>
                                    <p>Technical Lead</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV-Yhnl1zs8uxQCNHNeJQLo4_rBJSfsNB3GIXRtUjgvm2urrQ4TDwQI1y5foqcbXO9CUg&usqp=CAU" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Kartik Saxena</h4>
                                    <p>Lead Digital Marketing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUMYuXFInhhyc_Z4VNLSFacQQ7XdH3eEPZTQ&s" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Kumar Prince</h4>
                                    <p>Sr App Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://www.gavinpublishers.com/admin/assets/editors_image/1508137009updated_picture91408590.jpg" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Rahul Singh</h4>
                                    <p>App Developer</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://sipl.ind.in/wp-content/uploads/2022/07/dummy-user.png" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Vishal Mishra</h4>
                                    <p>FullStack Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://aitlbd.com/wp-content/uploads/2018/04/teacher2.jpg" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Vishnu Nath Tiwari</h4>
                                    <p>UI Developer</p>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://st4.depositphotos.com/3557671/23892/v/450/depositphotos_238923408-stock-illustration-vector-illustration-of-avatar-and.jpg" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#">Shubham Vishwakarma</a></h4>
                                    <p>Sr. Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtIGVupSyE17k0s5mH43ut12XoKYUgKCi6bQ&s" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Akansha Omar</h4>
                                    <p>Jr. Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTCf9j-rBHV6o3fB4jPUzqnD878ZclZlZW0UfOYzAYrBugAGyjdNVrPdBdcvQg1qzDlaI&usqp=CAU" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4>Anchal Shukla</h4>
                                    <p>Jr. Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://set.kuleuven.be/en/education/innovation/moocsfc/images/staff/team-dummy-woman.png/@@images/image.png" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#">Madhu Singh Rajput</a></h4>
                                    <p>Jr. Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHH-oa9mpMbrEDiKbdW8ulzRPT6jvNZpSvfnAc7YgP5SUDe2CUrqrcuJoOszNIaLINAzg&usqp=CAU" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#">Sonali Gupta</a></h4>
                                    <p>Jr. App Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="full-image-card hover-scale">
                                <div className="image-div"><a href="#"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgxLwGWBEdrm8-ocvjzywi_1_LtclCIUMLPQ&s" alt="team" className="img-fluid" /></a></div>
                                <div className="info-text-block">
                                    <h4><a href="#">Shubham Agrawal</a></h4>
                                    <p>Video/Editor</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Ourteam;
